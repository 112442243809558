/* generated */ $argv: (production: false, ftp: false);
////
/// @group style.scss
////

// сбор всех стилей для текстового редактора
// -------------------------------------------------

@import "_settings/all";

@import "_blocks/wysiwyg";
