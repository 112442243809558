////
/// @group _blocks/
////

@import "_settings/functions/vw";
@import "_settings/functions/em-rem";

/// Описание
.wysiwyg {
	@include headers();
	$gap: em(35px);
	color: inherit;

	p,
	ul,
	ol,
	dl,
	pre,
	table:not(.draggable-table__table),
	blockquote,
	address,
	.ratio-wrapper,
	.draggable-table {
		margin: $gap 0;
	}

	table {
		border: 1px solid;
		border-collapse: collapse;
		width: 100%;
		vertical-align: top;

		p,
		ul,
		ol,
		dl,
		pre,
		table,
		blockquote,
		address {
			margin: 0;
		}
	}

	thead,
	tfoot,
	tbody,
	tr,
	th,
	td {
		border: inherit;
		vertical-align: inherit;
	}

	th,
	td {
		padding: rem(8px);
	}

	hr {
		margin: $gap * 2 0;
	}

	figure {
		margin-left: 0;
		margin-right: 0;
		padding: 1rem;
		text-align: center;
		border: 1px solid;
		box-sizing: border-box;

		p {
			margin: 0;
		}
	}

	figcaption {
		padding-top: .5rem;
		font-style: italic;
	}

	blockquote {
		padding: 0 $gap;
		border: 1px solid transparent;
		border-radius: map-get($sizes, border-radius);
		background-color: #e9e9e9;
	}

	//pre {
	//	border-radius: .3em;
	//	text-shadow: 0 1px map-get($colors, white);
	//	font-family: map-get($font-family, monospace);
	//	background-color: #fdf6e3;
	//	tab-size: 4;
	//}

	:not(pre) > code,
	kbd,
	samp {
		border-radius: .3em;
		text-shadow: 0 1px #fff;
		font-family: map-get($font-family, monospace);
		padding: .1em 6px;
		white-space: normal;
		hyphens: none;
		tab-size: 4;
		word-spacing: normal;
		word-break: normal;
		word-wrap: normal;
		text-align: left;
	}

	:not(pre) > code {
		background-color: #fdf6e3;
	}

	kbd {
		background-color: #e5e5fb;
	}

	samp {
		background-color: #fde6e3;
	}

	dt {
		font-weight: bold;
	}

	dd + dt {
		margin-top: $gap / 2;
	}

	a {
	  display: inline-block;
	  font-size: 14px;
	  color: #000000;
	  text-decoration: underline;

	  &:hover {
		color: #cccccc;
	  }

		&[href^="//"],
		&[href^="http://"],
		&[href^="https://"] {
			&[target="_blank"] {
				background: url(./static/pic/external-link-hover.svg) top left / 0 no-repeat, url(./static/pic/external-link-active.svg) top left / 0 no-repeat;

				&::after {
					content: "";
					width: 1em;
					height: 1em;
					margin-left: 1px;
					display: inline-block;
					vertical-align: middle;
					background: url(./static/pic/external-link.svg) no-repeat;
					background-size: contain;
				}

				&:hover {
					&::after {
						background-image: url(./static/pic/external-link-hover.svg);
					}
				}

				&:active {
					&::after {
						background-image: url(./static/pic/external-link-active.svg);
					}
				}
			}
		}

		&[href^="mailto:"] {
			&::before {
				content: "\2709\00a0";
			}
		}

		&[href^="tel:"] {
			&::before {
				content: "\2706\00a0";
			}
		}
	}

	> :first-child {
		margin-top: 0;
	}

	h2 {
		font: {
			size: 20px;
			weight: 500;
		};
		line-height: 36px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		color: #000;

		@include media(ms) {
			font-size: rem(18px);
			text-align: left;
		}

		@include media(def) {
			font-size: vw(20px);
		}

		@include media(lg) {
			font-size: vw(24px);
			margin-bottom: 0;
		}
	}

	p {

		font-size: 16px;
		line-height: 22px;


		@include media(ms) {
			font-size: 16px;
			line-height: 26px;
		}

		@include media(def) {
			font-size: vw(22px);
			line-height: vw(30px);
		}

		@include media(lg) {
			font-size: vw(18px);
			line-height: vw(30px);
		}
	}

	ol {
		counter-reset: item;
		padding: 0;

		li {
			display: block;
			font-size: 18px;
			margin-right: 5px;

			&::before {
				content: counter(item) ". ";
				counter-increment: item;
				color: map-get($colors, green-dark);
				padding-right: rem(10px);
			}
		}
	}

	ul {
		li {
			font-size: rem(18px);
			line-height: rem(40px);
			letter-spacing: 0.025em;
			padding-left: rem(27px);
			margin-bottom: -10px;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 3px;
				height: 3px;
				left: 0;
				background-color: #85bf5b;
				border-radius: 100%;

			}
		}
	}

	&--brands-item {
		max-width: 561px;
	}

	&--main {
		text-align: center;

		@include media(ms) {
			text-align: right;
		}

		h1 {
			font: {
				size: 19px;
			};
			font-size: 19px;
			color: map-get($colors, dark);
			letter-spacing: 0.3em;
			text-transform: uppercase;
			margin-right: -0.4em;
			margin-bottom: vw(33px);

			@include media(sm) {
				letter-spacing: 0.4em;
			}

			@include media(ms) {
				text-align: right;
			}

			@include media(def) {
				font: {
					size: vw(40px);
					weight: 500;
				};
			}
		}

		p {
			font-size: 14px;
			line-height: 17px;
			max-width: 820px;
			letter-spacing: 0.025em;
			margin-bottom: 17px;
			margin-top: 16px;
			padding-left: 10px;

			@include media(ms) {
				margin-bottom: vw(56px);
				margin-top: inherit;
			}

			@include media(def) {
				font: {
					size: 12px;
				};
				line-height: vw(30px);
			}

			@include media(lg) {
				font: {
					size: vw(18px);
				};
				padding-left: 0;
			}
		}
	}

	&--tab {
		p {
			font-size: rem(18px);
			line-height: rem(30px);
			max-width: 430px;
			letter-spacing: 0.025em;
			color: #333;

			@include media(md) {
				margin-bottom: vw(31px);
			}

			@include media(def) {
				font: {
					size: 12px;
				};
				line-height: vw(30px);
			}

			@include media(lg) {
				font: {
					size: vw(18px);
				};
				line-height: vw(30px);
				padding-left: 0;
			}
		}
	}

	&--as-page {
		max-width: 925px;

	  p {
		margin-bottom: 0;
	  }

	  ul {
		margin-top: 10px;

		li {
		  line-height: 1;
		  margin-bottom: 12px;
		}
	  }

	  p, li, div {

		a {
		  display: inline-block !important;
		  margin-bottom: 0 !important;
		}
	  }

		a {
			display: block;
			margin-bottom: vw(79px);
			font-size: rem(14px);
			line-height: rem(18px);
			text-transform: uppercase;
			letter-spacing: 0.2em;
		  	color: #000;

		  &::after {
			display: none !important;
		  }
		}
	}

	&--brand-page {
		padding-right: 62px;

		@include media(ms) {
			padding-right: 62px;
		}

	  p, li, div {
		margin: $gap 0;
		a {
		  display: inline-block !important;
		  margin-bottom: 0 !important;
		}
	  }

		p {
			margin-bottom: vw(49px);
		}
	}

	&--map {

	  p, li, div {
		margin: $gap 0;
		a {
		  display: inline-block !important;
		  margin-bottom: 0 !important;
		}
	  }

		p {
			font-size: 15px;
			line-height: 22px;
			margin-bottom: 0;

			@include media(ms) {
				font-size: 15px;
				line-height: 22px;
				margin-bottom: 0;
			}

			@include media(def) {
				font-size: 15px;
				line-height: 22px;
				margin-bottom: 0;
			}

			@include media(lg) {
				font-size: 15px;
				line-height: 22px;
				margin-bottom: 0;
			}
		}
	}
}
