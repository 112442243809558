////
/// @group _settings/
////


/// Карта Шрифтов
/// @type {Map}
$font-family: (
	main: #{"Futura PT", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif},
	secondary: #{sans-serif},
	monospace: #{Consolas, Menlo, Courier, monospace}
);
