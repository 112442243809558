////
/// @group _settings/
////

/// Карта основых цветов
/// @type {Map}
$colors: (
	light: #fff,
	dark: #000,
	dark-lite: #333,
	main: #f0f,
	secondary: #313131,
	active: #ff0,
	bg: #e9e9e9,
	border: #ccc,
	text: #234,
	accent: #a1a1fc,
	warn: #c89600,
	error: #ff6635,
	green: #8dbf69,
	green-dark: #7fbc53,
	green_focus: #7fbc53,
	salat-light: #f3f8fa,
	salat-dark: #dde9ed,
	blue_light: #f3f8fa
);

/// Карта цветов для ссылок
/// @type {Map}
$colors-link: (
	default: map-get($colors, main),
	visited: darken(map-get($colors, main), 12%),
	hover: map-get($colors, secondary),
	active: map-get($colors, text)
)
