////
/// @group _settings/
////

/// Карта основых размеров
/// @type {Map}
$sizes: (
	container-width: 1280px,
	border-radius: 4px,
);

